<template>
    <div class="ship-info-cell">
        <div>
            <span>
                {{
                    rowData.vesselename +
                    " " +
                    "/"
                }}
            </span>
            <span>
                {{ " " + rowData.voyage }}
            </span>
        </div>
        <el-dropdown>
            <img src="@/assets/icon/copy.svg"/>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-clipboard:copy="rowData.vesselename" v-clipboard:success="copySucc">复制船名</el-dropdown-item>
                <el-dropdown-item v-clipboard:copy="rowData.voyage" v-clipboard:success="copySucc">复制航次</el-dropdown-item>
                <el-dropdown-item v-clipboard:copy="`${rowData.vesselename}/${rowData.voyage}`" v-clipboard:success="copySucc">复制全部</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
export default {
    name: 'ShipInfoCell',
    props: {
        rowData: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        copySucc() {
            this.$message.success('复制成功');
        }
    }
}
</script>

<style lang="stylus" scoped>
.ship-info-cell{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
        margin-left:4px;
        cursor: pointer;
        margin-top: 5px;
        &:hover{
            opacity: 0.6;
        }
    }
}
</style>