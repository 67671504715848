<template>
    <!-- 设置微信推送弹窗 -->
    <!-- <el-dialog
        title="推送设置"
        :visible.sync="dialogTableVisible"
        center
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        class="push_setting_ship_dialog"
    >
        
    </el-dialog> -->
    <SettingLayout title="船期-微信推送设置" v-if="dialogTableVisible" ref="settingRef"> 
        <div class="checkbox_group_div">
            <div class="checkbox_div">
                <div class="flex_div">
                    <p class="info_title">船舶推送：</p>
                    <el-checkbox
                        :indeterminate="isIndeterminateBoat"
                        v-model="checkAllboat"
                        @change="handleCheckAllChangeBoat"
                    >
                        全选
                    </el-checkbox>
                </div>
                <el-checkbox-group
                    v-model="checkedBoatlist"
                    @change="handleCheckedBoatChange"
                >
                    <el-checkbox
                        v-for="city in boatlist"
                        :label="city"
                        :key="city"
                    >
                        {{ city }}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="checkbox_div">
                <div class="flex_div">
                    <p class="info_title2">
                        异常推送：（为保障业务，异常推送默认开启，无法关闭。）
                    </p>
                </div>
                <el-checkbox-group
                    v-model="checkedErrorlist"
                    @change="handleCheckedErrorChange"
                >
                    <el-checkbox
                        v-for="city in errorlist"
                        :label="city"
                        :key="city"
                        disabled
                    >
                        {{ city }}
                    </el-checkbox>
                </el-checkbox-group>
                <div class="flex_div" v-if="showTab === 'NB'">
                    <p class="info_title">临近截关时间设置：</p>
                    <el-select v-model="portclosetime" size="mini">
                        <el-option
                            v-for="item in timelist"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
                <div class="flex_div">
                    <p class="info_title">临近截港时间设置：</p>
                    <el-select v-model="ctnapplyendtime" size="mini">
                        <el-option
                            v-for="item in timelist"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <DialogFooter @confirm="updateSet" @cancel="closeDialog" top="20" />
    </SettingLayout>
</template>
<script>
import { pushSetting, pushSettingUpdate } from "@/api/push";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import SettingLayout from "@/components/layout/SettingLayout.vue";
export default {
    props: {
        showTab: {
            type: String,
            default: "NB",
        },
    },
    data() {
        return {
            test: "测试信息",
            dialogTableVisible: false,
            ispushson: false,
            checkAllboat: true,
            checkAllbox: true,
            checkAllError: false,
            //船舶推送
            boatlist: [
                "进箱开始/截止时间推送",
                "截单/截关时间及变更推送",
                "预计抵离港时间及变更推送",
                "已抵港/离泊推送",
            ],
            checkedBoatlist: [],
            //箱货推送
            boxlist: [
                "海关预配已接受推送",
                "已进港推送",
                "海关已放行推送",
                "码头已放行推送",
            ],
            checkedBoxlist: [],
            //异常推送
            errorlist: ["海关查验推送", "码放异常推送"],
            checkedErrorlist: ["海关查验推送", "码放异常推送"],
            //indeterminate 属性用以表示 checkbox 的不确定状态，一般用于实现全选的效果
            isIndeterminateOne: false,
            isIndeterminateBoat: false,
            isIndeterminateBox: false,
            isIndeterminateError: true,
            portclosetime: "", //截关时间
            ctnapplyendtime: "", //截港时间
            timelist: [
                { value: 12, label: "12小时" },
                { value: 18, label: "18小时" },
                { value: 24, label: "24小时" },
                { value: 30, label: "30小时" },
                { value: 36, label: "36小时" },
            ],
            tempcheckeddata: {},
        };
    },
    components: {
        DialogFooter,
        SettingLayout
    },
    mounted() {},
    methods: {
        init() {
            this.dialogTableVisible = true;
            this.$nextTick(()=>{
                this.$refs.settingRef.init();
            })
            this.checkedBoatlist = [];
            this.checkedBoxlist = [];
            this.getSetting();
        },
        handleCheckAllChangeBoat(val) {
            this.checkedBoatlist = val ? this.boatlist : [];
            this.isIndeterminateBoat = false;
        },
        handleCheckedBoatChange(value) {
            let checkedCount = value.length;
            this.checkAllboat = checkedCount === this.boatlist.length;
            this.isIndeterminateBoat =
                checkedCount > 0 && checkedCount < this.boatlist.length;
        },
        handleCheckAllChangeBox(val) {
            this.checkedBoxlist = val ? this.boxlist : [];
            this.isIndeterminateBox = false;
        },
        handleCheckedBoxChange(value) {
            let checkedCount = value.length;
            this.checkAllbox = checkedCount === this.boxlist.length;
            this.isIndeterminateBox =
                checkedCount > 0 && checkedCount < this.boxlist.length;
        },
        handleCheckAllChangeError(val) {
            this.checkedErrorlist = val ? this.errorlist : [];
            this.isIndeterminateError = false;
        },
        handleCheckedErrorChange(value) {
            let checkedCount = value.length;
            this.checkAllError = checkedCount === this.errorlist.length;
            this.isIndeterminateError =
                checkedCount > 0 && checkedCount < this.errorlist.length;
        },
        updateSet() {
            this.pushSetting();
        },
        getSetting() {
            var that = this;

            pushSetting().then(function (response) {
                var data = response.data;
                // if (data.data.isPushSon === 1) {
                //     that.ispushson = false;
                // } else if (data.data.ispushson === 0) {
                //     that.ispushson = true;
                // }
                that.ispushson = Boolean(data.data.isPushSon);
                that.portclosetime = data.data.portCloseH;
                that.ctnapplyendtime = data.data.ctnApplyEndH;
                that.$store.commit("ship/editPorttime", data.data.portCloseH);
                that.$store.commit(
                    "ship/editCtnapplyend",
                    data.data.ctnApplyEndH,
                );
                //箱货推送
                var ispreentry = data.data.isPre; //海关预配已接受推送
                var isingate = data.data.isInGate; //已进港推送
                var iscustpass = data.data.isCustpass; //海关已放行推送
                var isnpp = data.data.isNpp; //码头已放行推送
                if (ispreentry === 1) {
                    that.checkedBoxlist.push(that.boxlist[0]);
                }
                if (isingate === 1) {
                    that.checkedBoxlist.push(that.boxlist[1]);
                }
                if (iscustpass === 1) {
                    that.checkedBoxlist.push(that.boxlist[2]);
                }
                if (isnpp === 1) {
                    that.checkedBoxlist.push(that.boxlist[3]);
                }
                //船舶推送
                var isctnapply = data.data.isCtnApply; //进箱开始/截止时间推送
                var isclosedate = data.data.isClose; //截单/截关时间及变更推送
                var isetadate = data.data.isEta; //预计抵离港时间及变更推送
                var isarrival = data.data.isArrival; //已抵港/离泊推送
                if (isctnapply === 1) {
                    that.checkedBoatlist.push(that.boatlist[0]);
                }
                if (isclosedate === 1) {
                    that.checkedBoatlist.push(that.boatlist[1]);
                }
                if (isetadate === 1) {
                    that.checkedBoatlist.push(that.boatlist[2]);
                }
                if (isarrival === 1) {
                    that.checkedBoatlist.push(that.boatlist[3]);
                }
                that.tempcheckeddata = {
                    ctnapplyendtime: data.data.ctnApplyEndH,
                    isarrival,
                    isclosedate,
                    isctnapply,
                    iscustpass,
                    isetadate,
                    isingate,
                    isnpp,
                    ispreentry,
                    ispushson: data.data.ispushson,
                    portclosetime: data.data.portCloseH,
                    userid: data.data.userId,
                };
                that.handleCheckedBoatChange(that.checkedBoatlist);
                that.handleCheckedBoxChange(that.checkedBoxlist);
                if (this.checkedBoatList.length === that.boatlist.length) {
                    this.checkAllboat = true;
                }
            });
        },
        pushSetting() {
            let data = {};
            data.portCloseH = this.portclosetime;
            data.ctnApplyEndH = this.ctnapplyendtime;
            //箱货
            data.isPre = this.checkedBoxlist.includes("海关预配已接受推送")
                ? 1
                : 0;
            data.isInGate = this.checkedBoxlist.includes("已进港推送") ? 1 : 0;
            data.isCustpass = this.checkedBoxlist.includes("海关已放行推送")
                ? 1
                : 0;
            data.isNpp = this.checkedBoxlist.includes("码头已放行推送") ? 1 : 0;
            //船舶
            data.isCtnApply = this.checkedBoatlist.includes(
                "进箱开始/截止时间推送",
            )
                ? 1
                : 0;
            data.isClose = this.checkedBoatlist.includes(
                "截单/截关时间及变更推送",
            )
                ? 1
                : 0;
            data.isEta = this.checkedBoatlist.includes(
                "预计抵离港时间及变更推送",
            )
                ? 1
                : 0;
            data.isLoad = this.checkedBoatlist.includes("已装船推送") ? 1 : 0;
            data.isArrival = this.checkedBoatlist.includes("已抵港/离泊推送")
                ? 1
                : 0;
            data.isPushSon = this.ispushson ? 0 : 1;

            if (this.isObjectChanged(data, this.tempcheckeddata)) {
                this.closeDialog();
                return;
            } else {
                var that = this;
                pushSettingUpdate(data).then(function (response) {
                    that.$store.commit("ship/editPorttime", that.portclosetime);
                    that.$store.commit(
                        "ship/editCtnapplyend",
                        that.ctnapplyendtime,
                    );

                    that.closeDialog();
                });
            }
        },
        closeDialog() {
            this.dialogTableVisible = false;
        },
        isObjectChanged(source, comparison) {
            const _source = JSON.stringify(source);
            const _comparison = JSON.stringify({ ...source, ...comparison });
            return _source === _comparison;
        },
    },
};
</script>
<style lang="stylus" scoped>
.checkbox_group_div {
  display: flex;
  flex-direction: column;
  padding-bottom:20px;
  border-bottom:2px solid #ebeef5;
}

.checkbox_div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.flex_div {
  display: flex;
  flex-direction: row;
  align-items: center;

  .el-checkbox {
    margin-bottom: 0;
  }
}

.info_title {
  display: block;
  width: fit-content;
  height: 50px;
  line-height: 50px;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0;
}

.info_title:before {
  content: '';
  height: 2rem;
  margin-top: 12px;
  margin-right: 1rem;
  border-left: 2px solid #de5d40;
}

// 2345浏览器使用info_title会导致文字过长换行错位
.info_title2 {
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0;
}

.info_title2:before {
  content: '';
  height: 2rem;
  margin-top: 12px;
  margin-right: 1rem;
  border-left: 2px solid #de5d40;
}
</style>
